import React from 'react';
import SEO from '../components/seo';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import RecentPosts from '../components/recent_posts';
import css from '../styles/blog/post.module.scss';
import firebase from "gatsby-plugin-firebase";

export default function Post({data}) {
    const post = data.markdownRemark;
    const keywords = post.frontmatter.title.split(" ");
    // if (typeof window !== 'undefined')
    //     firebase.analytics().logEvent("visited_" + post.frontmatter.title.replace(/ /g, "_"));

    return (
            <Layout>
                <SEO title={post.frontmatter.title} desc={post.excerpt} keywords={keywords}/>
                <div className={css.content}>
                    <div className={css.post}>
                        <h1>{post.frontmatter.title}</h1>
                        <p>{post.frontmatter.date}</p>
                        <div dangerouslySetInnerHTML={{__html: post.html}}
                            className={css.postBody}></div>
                    </div>
                    <div className={css.recentContainer}>
                        <RecentPosts />
                    </div>
                </div>
            </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: {slug: {eq: $slug}}) {
            frontmatter {
                title
                date(formatString: "MMM Do, YYYY")
            }
            html
            excerpt
        }
    }
`